import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-tailwind/react";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { darkTheme, getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { mainnet, base, sepolia } from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { ToastContainer } from "react-toastify";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

window.Buffer = window.Buffer || require("buffer").Buffer;

const eth_mainnet = {
    ...mainnet,
    rpcUrls: {
        ...mainnet.rpcUrls,
        default: {
            http: [process.env.REACT_APP_ETH_RPC],
        },
        public: {
            http: [process.env.REACT_APP_ETH_RPC],
        },
    }
};

const base_mainnet = {
    ...base,
    rpcUrls: {
        ...base.rpcUrls,
        default: {
            http: [process.env.REACT_APP_BASE_RPC],
        },
        public: {
            http: [process.env.REACT_APP_BASE_RPC],
        },
    }
};

const sepolia_testnet = {
    ...sepolia,
    rpcUrls: {
        ...sepolia.rpcUrls,
        default: {
            http: ["https://ethereum-sepolia-rpc.publicnode.com"],
        },
        public: {
            http: ["https://ethereum-sepolia-rpc.publicnode.com"],
        },
    }
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
    [
        eth_mainnet,
        process.env.REACT_APP_BASE_RPC !== "" ? base_mainnet : base,
        sepolia_testnet,
    ],
    [publicProvider()]
);

const { connectors } = getDefaultWallets({
    appName: 'SnipeTokenBotETH',
    projectId: '4fe34ce6ff9047cd2fe34ce6ff9047cf',
    chains,
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider>
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains} theme={darkTheme()}>
                <BrowserRouter>
                    <App />
                    <ToastContainer theme="dark" position="top-center" pauseOnFocusLoss={false} autoClose={2500} hideProgressBar toastClassName="bg-gray-highlight font-sans" pauseOnHover={false} stacked />
                </BrowserRouter>
            </RainbowKitProvider>
        </WagmiConfig>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
