import { useContext, useEffect, useState } from "react";
import { generateAvatarURL } from "@cfx-kit/wallet-avatar";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

import { AppContext } from "../App";
import AvatarDropDown from "../components/AvatarDropdown";

export default function NavBar({ className, breadCrumb }) {
    const { user, logout } = useContext(AppContext);
    const { address } = useAccount();
    const [randomAvatar, setRandomAvatar] = useState(generateAvatarURL(new Date().getTime().toString()));

    const onViewProfile = () => {
        console.log("View Profile");
    };

    useEffect(() => {
        setRandomAvatar(generateAvatarURL(address?.toString() || new Date().getTime().toString()));
    }, [address]);

    return (
        <div className={`${className ? className : ""} font-sans flex-col lg:flex-row flex lg:justify-between lg:items-center lg:px-7 px-4 z-[50]`}>
            <div className="text-sm font-medium text-gray-normal whitespace-nowrap">{breadCrumb}</div>
            <div className="flex items-center justify-between w-full gap-2 lg:justify-end lg:gap-5">
                <ConnectButton.Custom>
                    {
                        ({
                            account,
                            chain,
                            openAccountModal,
                            openChainModal,
                            openConnectModal,
                            authenticationStatus,
                            mounted,
                        }) => {
                            const ready = mounted && authenticationStatus !== 'loading';
                            const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
                            if (!connected) {
                                return (
                                    <button className="px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none font-sans text-xs font-medium text-center text-white uppercase" onClick={openConnectModal}>
                                        Connect Wallet
                                    </button>
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <button className="px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-gray-highlight active:scale-95 transition duration-100 ease-in-out transform focus:outline-none font-sans text-xs font-medium text-center uppercase text-gray-normal" onClick={openChainModal}>
                                        Wrong Network
                                    </button>
                                );
                            }

                            return (
                                <div className="flex">
                                    <button
                                        className="px-2 h-8 lg:h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-semi border border-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                        onClick={openChainModal}>
                                        <img src="/assets/eth_logo.png" alt="chain" />
                                        <div className="font-sans text-xs font-medium text-center text-white uppercase">
                                            {chain.name}
                                        </div>
                                    </button>
                                    <button
                                        className="px-2 h-8 lg:h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-semi border border-red-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none ml-2"
                                        onClick={openAccountModal}>
                                        <div className="font-sans text-xs font-medium text-center text-white">
                                            {account.address.slice(0, 7) + "..." + account.address.slice(37)}
                                        </div>
                                    </button>
                                </div>
                            );
                        }
                    }
                </ConnectButton.Custom>
                <AvatarDropDown imageUrl={randomAvatar} name={user ? user.name : ""} address={address} onLogout={logout} onViewProfile={onViewProfile} />
            </div>
        </div>
    );
}
